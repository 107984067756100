import React from 'react'
import { navigate } from 'gatsby-link'

import styles from './consultant-form.module.css'

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

function partnerForm () {
  const [state, setState] = React.useState({})

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch((error) => alert(error))
  }
  return (
    <div className={styles.formBox}>
      <h3>Become a Partner:</h3>
      <form
        id='partner'
        className={styles.form}
        name='partner'
        method="post"
        action="/thanks/"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        onSubmit={handleSubmit}
        >
        <input type="hidden" name="form-name" value="partner" />
        <p hidden>
          <label>
            Don’t fill this out: <input name="bot-field" onChange={handleChange} />
          </label>
        </p>
        <input name="company" type="text" placeholder="Company Name"  onChange={handleChange} />
        <input name="website" type="text" placeholder="Website"  onChange={handleChange}/>
        <textarea name="strengths" form="partner" placeholder="(In short) Areas of focus. Add links to case studies." onChange={handleChange} />
        <div className={styles.inputGroup}>
          <input className={styles.inputShort} name="firstName" placeholder="First Name" onChange={handleChange} />
          <input className={styles.inputShort} name="lastName" placeholder="Last Name" onChange={handleChange} />
        </div>
        <input name="email" type="email" placeholder="Email" onChange={handleChange} />
        <input name="phone" type="text" placeholder="###-###-####"  onChange={handleChange} />
        <input name="location" type="text" placeholder="Location"  onChange={handleChange} />
        <input name="linkedin" type="text" placeholder="Linkedin Profile"  onChange={handleChange} />

        <input name="referral" type="text" placeholder="Referred By"  onChange={handleChange} />
        <input type="submit" />
      </form>
    </div>
  )
}

export default partnerForm
